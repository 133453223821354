import React, { useState } from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Seo from '../components/seo'
import _ from 'lodash'
import DateBlock from '../components/DateBlock'
import ReturnBack from '../components/ReturnBack'

const SummaryTemplate = props => {
  const moment = require('moment')

  const [buttonIsActive, toggleButton] = useState(false)

  const { markdownRemark } = props.data
  const { frontmatter, html } = markdownRemark

  // Set the title "prefix" for current summary. The suffix comes from the config file.
  const pageTitle = `Kooste: ${frontmatter.title}`

  return (
    <Layout>
      <Seo
        description={_.truncate(frontmatter.ingress, { length: 160 })}
        title={pageTitle}
      />
      <article className="uk-article article-frontpage uk-margin-small-top">
        <p className="article-type uk-text-left uk-text-uppercase uk-text-large article-type">
          Kooste
        </p>
        <h2 className="uk-article-title uk-margin-small-top uk-margin-small-top uk-margin-small-bottom">
          {frontmatter.title}
        </h2>
        <div className="uk-article-meta uk-text-default uk-margin-small">
          <DateBlock
            writtenOn={frontmatter.writtenOn}
            modifiedOn={frontmatter.modifiedOn}
          />
        </div>

        <p className={'uk-text-medium speaker'}>
          Alustaja(t) <strong>{frontmatter.speaker}</strong>
        </p>

        <button
          onClick={event => toggleButton(!buttonIsActive)}
          href="#toggle-animation"
          className="uk-button uk-button-default uk-margin-medium-bottom"
          type="button"
          uk-toggle="target: #toggle-animation; animation: uk-animation-fade"
        >
          {buttonIsActive ? 'Piilota lisätiedot' : 'Näytä lisätiedot'}
        </button>
        <div id="toggle-animation" hidden="true" className={'uk-text-medium'}>
          <dl>
            <dt className={'date'}>Ajankohta</dt>
            <dd>{moment(frontmatter.date).format('D.M.YYYY [klo] HH:mm')}</dd>
            <dt className={'location'}>Kokoontumispaikka</dt>
            <dd>{frontmatter.location}</dd>
            <dt className={'categories'}>Kuuluu kategorioihin</dt>
            <dd>{frontmatter.categories}</dd>
            <dt className={'tags'}>Avainsanat</dt>
            <dd>{frontmatter.tags.join(', ')}</dd>
            <dt className={'hymn'}>Alkuvirsi</dt>
            <dd>
              {frontmatter.hymnNumberAndTitle ? (
                <a
                  href={frontmatter.hymnLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {frontmatter.hymnNumberAndTitle}
                </a>
              ) : (
                '-'
              )}
            </dd>
            <dt className={'manpower'}>Miesvahvuus</dt>
            <dd>{frontmatter.participants}</dd>
          </dl>
        </div>

        <div className={'uk-text-medium body'}>
          <div dangerouslySetInnerHTML={{ __html: html }} />
        </div>
        <ReturnBack />
      </article>
    </Layout>
  )
}

export default SummaryTemplate

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      fields {
        slug
      }
      frontmatter {
        date
        ingress
        path
        title
        categories
        writtenOn
        modifiedOn
        participants
        speaker
        tags
        hymnLink
        hymnNumberAndTitle
        location
      }
    }
  }
`
